@import "./variables";

.form h2 {
  text-align: center;
}

// form wizard
#msform {
  text-align: center;
  position: relative;
  margin-top: 20px;
}

#msform fieldset .form-card {
  background: white;
  border: 0 none;
  border-radius: 0px;
  box-shadow: 0 2px 2px 2px rgba(0, 0, 0, 0.2);
  padding: 20px 10px 30px 10px;
  box-sizing: border-box;
  width: 97%;
  margin: 0 auto;
  position: relative;
}

#msform fieldset {
  background: white;
  border: 0 none;
  border-radius: 0.5rem;
  box-sizing: border-box;
  width: 100%;
  margin: 0;
  padding-bottom: 10px;
  position: relative;
}

#msform fieldset:not(:first-of-type) {
  display: none;
}

#msform fieldset .form-card {
  text-align: left;
  color: #9e9e9e;
}

#msform input,
#msform textarea {
  padding: 0px 8px 4px 8px;
  border: none;
  border-bottom: 1px solid #ccc;
  border-radius: 0px;
  margin-bottom: 25px;
  margin-top: 2px;
  width: 100%;
  box-sizing: border-box;
  font-family: montserrat;
  color: #2c3e50;
  font-size: 16px;
  letter-spacing: 1px;
}

#msform input:focus,
#msform textarea:focus {
  -moz-box-shadow: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  border: none;
  font-weight: bold;
  border-bottom: 2px solid skyblue;
  outline-width: 0;
}

select.list-dt {
  border: none;
  outline: 0;
  border-bottom: 1px solid #ccc;
  padding: 2px 5px 3px 5px;
  margin: 2px;
}

select.list-dt:focus {
  border-bottom: 2px solid skyblue;
}

.card {
  z-index: 0;
  border: none;
  border-radius: 0.5rem;
  position: relative;
}

.fs-title {
  font-size: 25px;
  color: #2c3e50;
  margin-bottom: 10px;
  font-weight: bold;
  text-align: left;
}

.progressbar {
  width: 100%;
  padding: 0;
  margin: 0 0 20px 0;
  color: lightgrey;
  display: flex;
  justify-content: center;
}

.progressbar .active {
  color: #000000;
}

.progressbar li {
  list-style-type: none;
  font-size: 12px;
  width: 25%;
}

.progressbar .formStep1:before {
  content: "1";
}

.progressbar .formStep2:before {
  content: "2";
}

.progressbar .formStep3:before {
  content: "3";
}

.progressbar .formStep4:before {
  content: "4";
}

.progressbar .formStep5:before {
  content: "5";
}

.progressbar .formStep6:before {
  content: "6";
}

.progressbar li:before {
  width: 40px;
  height: 40px;
  line-height: 35px;
  display: block;
  font-size: 17px;
  color: #ffffff;
  background: lightgray;
  border-radius: 50%;
  margin: 0 auto 10px auto;
  padding: 1px;
}

.progressbar li:after {
  content: "";
  width: 100%;
  height: 2px;
  background: lightgray;
  position: absolute;
  left: 0;
  top: 20px;
  z-index: -1;
}

.progressbar li.active:before,
.progressbar li.active:after {
  background: $primary;
}

.debtFormBtn {
  width: 120px;
  margin: 15px 5px 0 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

// final form step icons
.question-icon {
  font-size: 4em;
  color: $primary;
  border: 3px solid $primary;
  border-radius: 50%;
}

.tick-icon {
  font-size: 4em;
  color: $success;
  border: 3px solid $success;
  border-radius: 50%;
}
