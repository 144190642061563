@import "./variables";
@import "~bootstrap/scss/bootstrap";
@import "./bootswatch";

$fa-font-path: "../../static/webfonts";
@import "./fontawesome/fontawesome.scss";
@import "./fontawesome/solid.scss";
@import "./fontawesome/brands.scss";

.page {
  min-height: 100vh;
  font-family: $font-family-sans-serif !important;
}

.full-height {
  min-height: 70vh;
}

#nav-logo {
  height: 40px;
  margin-right: 2px;
}

.nav-link {
  letter-spacing: 1px;
}

#nav-phone-link {
  font-size: 1.5em;
  height: 40px;
  width: 56px;
  border: 1px solid $blue;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav-phone {
  color: $blue;
}

.desktop-phone-link {
  font-weight: bold;
  border: 1px solid $blue;
  padding: 5px;
  border-radius: 5px;
}

.jumbotron {
  height: 60vh;
  background-image: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0.404),
      rgba(255, 255, 255, 0.35)
    ),
    url(../../static/images/beach.webp);
  background-position: center, center;
  background-size: cover, cover;
  background-repeat: no-repeat, no-repeat;
  margin-bottom: 0px !important;
}

@media screen and (max-width: 768px) {
  .jumbotron {
    height: 54vh;
    background-image: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0.404),
        rgba(255, 255, 255, 0.35)
      ),
      url(../../static/images/beach-small.webp);
  }
}

.jumbotron-content {
  z-index: 2;
  letter-spacing: 2px;
}

@media screen and (min-width: 768px) {
  #indexEligibleForm {
    transform: translateY(-17vh);
    z-index: 2;
    position: absolute;
  }
}

ul.info-section-list li {
  list-style-type: circle;
}

.type-icon {
  font-size: 4em;
}

.type-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1em 0;
}

input[type="checkbox"] {
  -ms-transform: scale(1.3); /* IE */
  -moz-transform: scale(1.3); /* FF */
  -webkit-transform: scale(1.3); /* Safari and Chrome */
  -o-transform: scale(1.3); /* Opera */
}

#bottomSection {
  min-height: 300px;
}

@media screen and (max-width: 768px) {
  #bottomSection {
    min-height: 100px;
  }
}

#bottomRightImage {
  background-image: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0.27),
      rgba(255, 255, 255, 0.27)
    ),
    url(../../static/images/computer-family.webp);
  background-position: center, center;
  background-size: cover, cover;
  background-repeat: no-repeat, no-repeat;
  margin-bottom: 0px !important;
}

@media screen and (max-width: 768px) {
  #bottomRightImage {
    background-image: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0.27),
        rgba(255, 255, 255, 0.27)
      ),
      url(../../static/images/computer-family-small.webp);
  }
}

.arrow-right {
  right: -30px;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  width: 0;
  z-index: 1000;
  height: 0;
  border-style: solid;
  border-width: 30px 0px 30px 30px;
  border-color: transparent transparent transparent rgba(0, 0, 0, 0.125);
}

.arrow-right:after {
  display: block;
  content: "";
  position: absolute;
  right: 1px;
  top: -30px;
  width: 0;
  z-index: 1000;
  height: 0;
  border-style: solid;
  border-width: 30px 0px 30px 30px;
  border-color: $primary transparent $primary $light;
}

.arrow-down {
  bottom: -30px;
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
  width: 0;
  z-index: 1000;
  height: 0;
  border-style: solid;
  border-width: 30px 30px 0px 30px;
  border-color: rgba(0, 0, 0, 0.125) transparent transparent transparent;
}

.arrow-down:after {
  display: block;
  content: "";
  position: absolute;
  right: -30px;
  top: -30px;
  width: 0;
  z-index: 1000;
  height: 0;
  border-style: solid;
  border-width: 30px 30px 0px 30px;
  border-color: $light $primary transparent $primary;
}

@media screen and (max-width: 768px) {
  .index-flow-card {
    margin: 10px;
  }

  .index-card-container {
    padding-bottom: 30px;
  }
}

.index-card-icon {
  font-size: 3em;
}
